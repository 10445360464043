import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
query{
  currentUser{
    id
    authType
    username
    prefix
    fname
    lname
    role
    email
    faculty
    wallet
    ssoType
    studentID
    currentTime
  }
}
`;

export const GET_USERS = gql`
query{
  users{
    id
    authType
    username
    prefix
    fname
    lname
    role
    email
    faculty
    wallet
    ssoType
  }
}
`;
export const GET_STAFF_USERS = gql`
query{
  staffUsers{
    id
    authType
    username
    prefix
    fname
    lname
    fullname
    role
    email
    faculty
    wallet
    ssoType
  }
}
`;
export const GET_STUDENTS = gql`
query{
  students{
    id
    authType
    username
    studentID
    mobile
    prefix
    fname
    lname
    fullname
    role
    email
    faculty
    wallet
    ssoType
  }
}
`;
export const GET_COURSES = gql`
{
  courses {
    id
    idnumber
    fullname
    shortname
  }
}
`;
export const GET_COURSES_DB = gql`
{
  coursesDB {
    id
    idnumber
    fullname
    shortname
    schedules {
      id
      title
      detail
      month
      year
      code
      registerStart
      registerEnd
      rounds {
        id    
        location {
          id
          name
        }    
      }
    }
    meCanRegistration
  }
}
`;
export const GET_COURSES_WITH_CONTENTS = gql`
{
  courses {
    id
    idnumber
    fullname
    shortname
    courseContent {
      id
      name
      modules{
        id
        name
        instance
        contextid
        visible
        modicon
        modname
        modplural
      }
    }
  }
}
`;
export const GET_COURSE = gql`
query COURSE($id:ID){
  course(id:$id) {
    id
    idnumber
    fullname
    shortname
    summary
  }
}
`;
export const GET_DB_COURSE = gql`
query COURSE_DB($mdCourseID: ID) {
  courseDB(mdCourseID: $mdCourseID) {
    id
    fullname
    name
    shortname
    summary
    idnumber
    defaultCourse
    mdCourseID
    ssoRoles
    customRoleJSON
    langFilterField
    langFilterValue
    locations {
      id
      name
      address
      maxSeats
    }
    dqQuizzes{
      id
      name
      isDQWorkshop
      th{
        id
      }
      en{
        id
      }
    }
    isPaid
    freeTimes
    quizSet {
      id
      name
      quiz_mode
      retest_mode
      price
      minutes
      text_color
      bg_color
      dqQuizzes {
        id
      }
    }
    orConditions{
      andConditions{
        id
        quizSet{
          id
        }
        condition
      }
    }
    quizzes {
      id
      cmid
      instanceID
      visible
      modicon
      passGrade
      price
      name
      notExisted
    }
  }
}
`;
export const GET_COURSE_CONTENTS = gql`
query CourseContent($id:ID){
  courseContent(id:$id){
    name
    modules{
      id
      name
      instance
      contextid
      visible
      modicon
      modname
      modplural
    }
  }
}
`;
export const GET_ROLES = gql`
{
 roles {
   id
   name
   displayName
 }
}
`;

export const GET_EXAM_SCHEDULES = gql(`
query ExamSchedules($course: ID) {
  examSchedules(course: $course) {
    id
    title
    month
    year
    course {
      fullname
    }
    detail
    examStart
    examEnd
    gradeDate
    scoreDate
    code
    scheduleType{
        id
        name
    }
    registerStart
    registerEnd
    rounds {
      id
      schedule{
        id
      }
      examStart
      examEnd
      maxSeats
      location {
        id
        name
      }
      created
    }
  }
}
`)

export const GET_EXAM_SCHEDULE = gql(`
query ExamSchedule($id: ID) {
  examSchedule(id: $id) {
    id
    title
    month
    year
    course {
      id
      fullname
    }
    scheduleType {
      id
      code
      name
      detail
    }
    code
    useSeb
    show
    detail
    examStart
    examEnd
    scoreDate
    gradeDate
    registerStart
    registerEnd
    rounds {
      id
      quizSet {
        id
      }
      schedule {
        id
      }
      examStart
      examEnd
      maxSeats
      location {
        id
        name
      }
      created
    }
  }
}
`)

export const START_EXAM = gql(`
query StartExam($id: ID) {
  startExam(id: $id) {
    token
    quizURL {
      dqQuiz {
        id
        name
      }
      quiz {
        id
        cmid
        instanceID
        name
        passGrade
      }
      url
    }
  }
}
`);

export const GET_REGISTRATION_BY_ID = gql`
query RegistrationByID($id: ID) {
  registrationByID(id: $id) {
    id
    user {
      id
      username
      prefix
      fname
      lname
      fullname
      department
      faculty
      email
      studentID
      currentTime
    }
    course {
      id
      fullname
      name
    }
    schedule {
      id
      title
      month
      year
      useSeb
    }
    scheduleRound {
      id
      examStart
      examEnd
      quizSet {
        id
        name
        minutes
        text_color
        bg_color
      }
      location {
        id
        name
      }
    }
    checkIn {
      avatarName
      approved
      messages {
        content
        created
      }
      messageUpdate
      rejectMessages {
        content
        created
      }
      rejectMessageUpdate
      lastUpdate
      scheduleRoundCheckIn {
        groupName
        googleMeetURL
        lastUpdate
      }
    }
    examResult {
      id
      lang
      quizResults {
        dqQuiz {
          id
          name
          th {
            id
          }
          en {
            id
          }
          course {
            id
            fullname
            name
            shortname
          }
        }
        name
        score
        
      }
    }
    currentRegistrationTimes
  }
}
`;

export const GET_USER_SCORE = gql(`
{
  getScore {
    course {
      id
      name
      quizSet {
        id
        name
      }
    }
    register {
      id
      rounds {
        id
        scheduleRound {
          id
          location {
            id
            name
            address            
          }
          examStart
          examEnd      
          maxSeats
          schedule {
            id
            title
            detail
            month
            year
            registerStart
            registerEnd
          }
          quizSet {
            id
            name
          }
        }
        mdUser
        mdEmail
        examResult {
          id
          score
        }
      }
    }
  }
}
`);


export const GET_ALL_SCHEDULE_WITH_COUNTER = gql(`
query getAllSchedules($past: Boolean) {
  getAllSchedules(past: $past) {
    id
    title
    month
    year
    examStart
    examEnd
    scoreDate
    gradeDate
    registerStart
    registerEnd
    registeredCount
    course {
      id
      fullname
      name
      shortname
      idnumber
      quizSet {
        id
        name
        minutes
      }
    }
    rounds {
      id
      examStart
      examEnd
      maxSeats
      registeredCount
      location {
        id
        name  
        address
      }
      quizSet{
        id
        name        
      }      
    }
  }
}
`);

export const GET_SCHEDULE_BY_COURSE_ID = gql`
query GetScheduleByCourse($courseID: ID!) {
  getScheduleByCourse(courseID: $courseID) {
    id
    name
    idnumber
    isPaid
    ssoRoles
    schedules {
      id
      title
      detail
      month
      year
      registerStart
      registerEnd
      registeredCount
      rounds {
        id
        quizSet {
          id
          name
          price
          minutes
          text_color
          bg_color
        }
      }
    }
  }
}
`;

export const GET_COURSE_DB_WITH_ROUNDS=gql(`
query CourseDBbyID($id:ID,$schdID:ID){
  courseDBbyID(id:$id) {
    id
    shortname
    fullname
    idnumber
    summary
    isPaid
    freeTimes
    orConditions {
      andConditions {
        id
        quizSet {
          id
          name
          price
          minutes
        }
        condition
      }
    }
    quizSet{
      id
      name
      price
      minutes      
      text_color
      bg_color
    }
    schedules(schdID:$schdID) {
      id
      title
      detail
      month
      year
      examStart
      examEnd
      scoreDate
      gradeDate
      registerStart
      registerEnd
      rounds {
        id
        examStart
        examEnd
        maxSeats
        registeredCount
        location {
          id
          name
          address
        }
        quizSet {
          id
          name
          price
          minutes
          text_color
          bg_color
        }
      }
    }
  }
}
`);

export const GET_STUDENT_REGISTRATIONS=gql(`
query StudentRegistrations($userID: ID) {
  studentRegistrations(userID: $userID) {
    id
    isRegistrationTime
    usedWallet
    created
    course {
      id
      fullname
      name
      shortname
      idnumber
    }
    schedule {
      id
      title
      detail
      code
      month
      year
      useSeb
      examStart
      examEnd
      scoreDate
      gradeDate
      registerStart
      registerEnd
    }
    scheduleRound {
      id
      examStart
      examEnd
      location {
        id
        name
      }
      quizSet {
        id
        name
        price
        minutes
        text_color
        bg_color
      }
    }
    bill {
      id
      registers {
        id
        usedWallet
        course {
          id
          name
        }
        schedule {
          id
          title
          month
          year
          registerStart
          registerEnd
        }
        scheduleRound {
          id
          examStart
          examEnd
          location {
            id
            name
          }
          quizSet {
            id
            quiz_mode
            name
            price
            text_color
            bg_color
          }
        }
      }
      amount
      isPaid
      expire
    }
    examResult {
      id
      register{
        scheduleRound{
          quizSet{
            quiz_mode
          }
        }
      }
      lang
      quizEnd
      quizResults {
        dqQuiz {
          id
          name
          th {
            id
            name
            passGrade
          }
          en {
            id
            name
            passGrade
          }
          course {
            id
            fullname
            name
            shortname
          }
        }
        name
        score
      }
    }
  }
}
`);

export const GET_STUDENT_REGISTRATIONS_OPTIMIZE_REGISTER=gql(`
query StudentRegistrations($userID: ID) {
  studentRegistrations(userID: $userID) {
    id
    isRegistrationTime
    usedWallet
    created
    course {
      id
      fullname
      name
      shortname
      idnumber
    }
    schedule {
      id
      title
      detail
      code
      month
      year
      useSeb
      examStart
      examEnd
      scoreDate
      gradeDate
      registerStart
      registerEnd
    }
    scheduleRound {
      id
      examStart
      examEnd
      location {
        id
        name
      }
    }
  }
}
`);

export const GET_SSO_ROLES = gql(`
{
  ssoRole {
    id
    name
    detail
  }
}
`);

export const SEARCH_USER = gql`
query SearchUser($text: String) {
  searchUser(text: $text) {
    id
    username
    studentID
    fname
    lname
    email
    citizenId
    department
    faculty
    sourceType
    ssoRole {
      id
      name
    }
    wallet
    oldStudentSchedule {
      id
      scheduleDetail {
        id
        SchdCode
        SchdMonth
        SchdYear
        DateExam_Desc_Th
        DateRegist_Desc_En
        billing {
          id
          transactionID
          amount
          isPaid
          isActive
          created
          items {
            id
            billing_id
            ModuleType
            stdRegistID
            SchdDetailID
          }
        }
      }
      round {
        id
        ExamDate
        ExamTimeStart
        ExamTimeEnd
      }
      score {
        id
        theory
        workshop {
          type
          filename
          send_time
          score
          approve
          approve_time
        }
      }
    }
  }
}
`;


export const GET_ALL_COURSES_WITH_SCHEDULE = gql`
{
  allCourseDB {
    id
    name
    fullname
    quizSet {
      id
      name
      price
      minutes
      text_color
      bg_color
    }
    schedules {
      id
      title
      detail
      month
      year
      registerStart
      registerEnd
      rounds {
        id
        quizSet {
          id
          name
        }
        examStart
        examEnd
        location {
          id
          name
          maxSeats
        }
        registeredCount
      }
    }
  }
}
`;

export const GET_ALL_FILTERS = gql`
{
  langFilter {
    id
    field
    detail
  }
}
`;

export const GET_ALL_USER_BILLS = gql`
{
  getBills {
    id
    wallet {
      id
      detail
      amount
      created
    }
    detail
    registers {
      id      
      course {
        id
        name
      }
      schedule {
        id
        title
        month
        year
        registerStart
        registerEnd
      }
      scheduleRound {
        id
        examStart
        examEnd
        location {
          id
          name
        }
        quizSet {
          id
          name
          price
          text_color
          bg_color
        }
      }
    }
    amount
    isPaid
    expire
  }
}
`;

export const GET_BILL_BY_MONTH = gql`
query GetRemoteBills($date: String, $mode: String) {
  getRemoteBills(date: $date, mode: $mode) {
    amount
    transaction_time
    cust_email
  }
}
`;

export const GET_SYSTEM_CONFIG = gql`
{
  systemConfig {
    wallet_enabled
    change_schedule_enabled
    auto_cancel_expired_bill
    bill_ttl_hours
    check_in_group_size
  }
}
`;

export const GET_SCHEDULE_PARTICIPANTS = gql`
query ScheduleParticipant($scheduleID: ID) {
  scheduleParticipant(scheduleID: $scheduleID) {
    id
    title
    month
    year
    examStart
    examEnd
    gradeDate
    scoreDate
    registeredCount
    registerStart
    registerEnd
    code
    course {
      id
      name
      fullname
      shortname
      summary
      locations {
        id
        name
        address
        maxSeats
      }
      isPaid
      freeTimes
      ssoRoles
      mdCourseID
    }
    scheduleType {
      id
      name
    }
    rounds {
      id
      examStart
      examEnd
      location {
        id
        name
        maxSeats
        address
      }
      maxSeats
      registeredCount
      quizSet {
        id
        name
        minutes
        text_color
        bg_color
      }
      participants {
        id
        schedule{
            id
            examStart
            examEnd
            course{
                id
            }
        }
        scheduleRound {
          quizSet {
            id
            name
            minutes
            text_color
            bg_color
          }
        }
        user {
          id
          username
          prefix
          fname
          lname
          authType
          email
          citizenId          
          ssoType
          ssoRole {
            id
            name
          }          
          studentID
          faculty
          department
          mobile
          fullname
        }
        checkIn{
          id
          avatarName
          approved
          lastUpdate
          scheduleRoundCheckIn{
            id
            groupName
            googleMeetURL
            lastUpdate
          }
        }
        examResult {
          id
          lang
          quizResults {
            attemptID
            examStat
            dqQuiz {
              id
              name
              th {
                id
                cmid
                course {
                  mdCourseID
                }
              }
              en {
                id
                cmid
                course {
                  mdCourseID
                }
              }
              course {
                id
                mdCourseID
                fullname
                name
                shortname
              }
            }
            name
            score
          }
        }
      }
    }
  }
}
`;

export const GET_COURSE_QUESTIONS = gql`
query CourseLocalQuestions($courseID: ID!) {
  courseLocalQuestions(courseID: $courseID) {
    id
    questionType
    maxScore
    name
    title
    detail
    metadata
    created
  }
}
`;

export const GET_EXAM_REPORT = gql`
query ExamScheduleReport($mdCourseID: ID, $scheduleID: ID) {
  courseDB(mdCourseID: $mdCourseID) {
    orConditions {
      andConditions {
        id
        quizSet {
          id
          name
          dqQuizzes {
            id
            en {
              id
              name
              cmid
              instanceID
              passGrade
            }
            th {
              id
              name
              cmid
              instanceID
              passGrade
            }
          }
        }
        condition
      }
    }
  }
  examScheduleReport(scheduleID: $scheduleID) {
    id
    user {
      id
      studentID
      username
      prefix
      fname
      lname
      fullname
      email
      faculty      
    }
    examResult {
      id
      register {
        id
        scheduleRound {
          id
          quizSet {
            id
            name
          }
        }
      }
      dqQuiz {
        id
        name
        en {
          id
          name
          cmid
          instanceID
          passGrade
        }
        th {
          id
          name
          cmid
          instanceID
          passGrade
        }
      }
      lang
      score
      attemptDataHtml
    }
  }
}
`;

export const GET_OLD_SCHEDULE = gql`
query OldSchedules {
  oldSchedules {
    id
    round {
      id
      SchdID
      StdExamCode
      IsPayment
      ExamDate
      ExamTimeStart
      ExamTimeEnd
      ModuleType
      NumberOfSeats
    }
    scheduleDetail {
      id
      SchdID
      SchdCode
      SchdMonth
      SchdYear
      billing {
        id
        transactionID
        amount        
        isPaid
        expire
        isActive
        created
        transaction_time
        cust_email        
      }
    }
    score {
      id
      theory
      workshop {
        type
        filename
        send_time
        score
        approve
        approve_time
      }
    }
  }
}
`;

export const MY_PROGRESSION = gql`
query MyProgression($userID:ID){
  myProgression(userID:$userID) {
    ems{
      theory
      word
      excel
      powerpoint
    }
    course {
      id
      idnumber
      name
      fullname
      shortname
      summary
    }
    conditions {
      quizSet {
        id
        name   
        quiz_mode
        dqQuizzes{
          id
          name     
          th {
            id
            passGrade
            price
          }
          en {
            id
            passGrade
            price
          }     
        }
      }
      pass
      results {
        dqQuizID
        name
        score
        pass
      }
    }
    pass
  }
  studentRegistrations(userID:$userID){
    id    
    examResult{
      quizResults{        
        score
      }
    }
  }
}
`;

export const OLD_EMS_USER = gql`
query OldStudentSchedule($username: String!) {
  oldStudentSchedule(username: $username) {
    id
    studentID
    username
    schedules {
      id
      scheduleDetail {
        SchdID
        SchdCode
        SchdMonth
        SchdYear
        DateRegist_Desc_En
        DateRegist_Desc_Th
      }
      round {
        ModuleType
        ExamDate
        ExamTimeStart
        ExamTimeEnd
        NumberOfSeats
      }
      score {
        theory
        workshop {
          type
          score
        }
      }
    }
  }
}
`;

export const GET_KKU_BILL = gql`
query GetKKURemoteBills($ref2: ID, $ref3: ID) {
  getKKURemoteBills(ref2: $ref2, ref3: $ref3) {
    id
    transaction_id
    transaction_time
    cust_name
    cust_email
    amount
    pay_status
    ref2
    ref3
    due_date
    __typename
  }
}
`;

export const GET_SCHEDULE_WITH_REF2 = gql`
{
  getSchedules{
    id
    title
    detail
    code
    course{
      id
      name
      shortname
      fullname
    }
    ref2
  }
}
`
