import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Fade,
    makeStyles,
    Paper,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography
} from "@material-ui/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import thLocale from '@fullcalendar/core/locales/th';
import {useQuery} from "@apollo/client";
import {
    GET_ALL_SCHEDULE_WITH_COUNTER,
    GET_COURSE_DB_WITH_ROUNDS,
    GET_STUDENT_REGISTRATIONS
} from "../../gql-query/select-query";
import moment from "moment";
import {GrUserAdd} from 'react-icons/gr';
import {IconContext} from "react-icons";
import {useParams} from "react-router-dom";
import XBreadcrumb from "../../components/xbreadcrumb";
import StudentBreadcrumb from "../../components/student-breadcrumb";

import {toast} from "react-toastify";
import _ from 'lodash';
import {Alert} from "@material-ui/lab";
import RegisterStep0 from "../../components/register-step-0";
import RegisterStep1 from "../../components/register-step-1";
import RegisterStep2 from "../../components/register-step-2";
import RegisterStep3 from "../../components/register-step-3";
import RegisterStep4 from "../../components/register-step-4";
import {useMobxState} from "../../mobx/global-context";
import {Tab, Tabs} from "react-bootstrap";
import RegisterTabs from "../../components/register-tabs";


const useStyles = makeStyles(theme=>({
    root:{
        '& .Mui-selected':{
            background: '#4dabf5',
            color:'#fff',
            transition:'background 500ms'
        },
        '& .MuiTab-textColorPrimary.Mui-disabled':{
            background:'#868686',
            color:'#dcdada',
            transition:'background 500ms',
        }
    }
}));

const Register = () => {

    const params = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const classes = useStyles();
    const state = useMobxState();

    const courseDB = useQuery(GET_COURSE_DB_WITH_ROUNDS, {
        variables: {
            id: params.courseID,
            schdID: params.scheduleID
        },
        fetchPolicy: 'cache-first',
    });

    const userRegistrations = useQuery(GET_STUDENT_REGISTRATIONS, {
        fetchPolicy: "cache-first"
    });

    useEffect(() =>{
        if(state.currentOperation && state.currentOperation.type=='change-schedule'){
            // state.registerQuizSet=state.currentOperation.reg.map(reg=>reg.registerRound.id)
        }
    },[state.currentOperation])

    useEffect(() =>{
        return () =>{
            state.resetRegisterData();
        }
    },[]);

    if (courseDB.loading || userRegistrations.loading) return <div>Loading...</div>
    return <Paper>
        <StudentBreadcrumb data={[
            {title: 'Register', url: '/my/register'},
            {title: courseDB.data.courseDBbyID.shortname}
        ]}/>
        <Box p={2}>
            <Paper square className={classes.root}>

                <RegisterTabs
                    currentStep={currentStep}
                    courseDB={courseDB}
                    scheduleID={params.scheduleID}
                    userRegistrations={userRegistrations}
                    setCurrentStep={setCurrentStep}
                />

                {/*<Tabs defaultActiveKey={0} id="uncontrolled-tab-example">*/}
                {/*    <Tab eventKey={0} title="Information">*/}
                {/*        <RegisterStep0 {...{courseDB,userRegistrations, setCurrentStep}}/>*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey={1} title="(1) Quiz">*/}
                {/*        c*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey={2} title="(2) Schedule" disabled>*/}
                {/*        cccccss*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey={3} title="(3) Confirm" disabled>*/}
                {/*        cccccss*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey={4} title="(4) Payment" disabled>*/}
                {/*        cccccss*/}
                {/*    </Tab>*/}
                {/*</Tabs>*/}
                {/*<Tabs*/}
                {/*    value={currentStep}*/}
                {/*    indicatorColor="primary"*/}
                {/*    textColor="primary"*/}
                {/*    onChange={(e,v)=>{*/}
                {/*        setCurrentStep(v);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Tab label="Info" disabled={state.registerStepIsFinished} />*/}
                {/*    <Tab label="1. Quiz" disabled={currentStep<1 || state.registerStepIsFinished}/>*/}
                {/*    <Tab label="2.Schedule" disabled={currentStep<2 || state.registerStepIsFinished}/>*/}
                {/*    <Tab label="3. Confirm" disabled={currentStep<3 || state.registerStepIsFinished}/>*/}
                {/*    <Tab label="4. Payment" disabled={currentStep<4}/>*/}
                {/*</Tabs>*/}
            </Paper>
        </Box>
        {/*{*/}
        {/*    currentStep == 0 && <RegisterStep0 {...{courseDB,userRegistrations, setCurrentStep}}/>*/}
        {/*}*/}
        {/*{*/}
        {/*    currentStep == 1 && <RegisterStep1 {...{courseDB,userRegistrations, setCurrentStep}}/>*/}
        {/*}*/}
        {/*{*/}
        {/*    currentStep == 2 && <RegisterStep2 {...{courseDB,userRegistrations, setCurrentStep}}/>*/}
        {/*}*/}
        {/*{*/}
        {/*    currentStep == 3 && <RegisterStep3 {...{courseDB,userRegistrations, setCurrentStep}}/>*/}
        {/*}*/}
        {/*{*/}
        {/*    currentStep == 4 && <RegisterStep4 {...{courseDB, setCurrentStep}}/>*/}
        {/*}*/}
    </Paper>
}

export default Register;
