import {observer} from "mobx-react";
import {useMobxState} from "../mobx/global-context";
import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Checkbox, Fade, FormControlLabel, Grid,
    makeStyles,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableHead, TableRow, Typography
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {
    getRegistrationCountWithThis,
    getThaiDate,
    getThaiDateBetween,
    getThaiDateBetweenSingleDay
} from "../services/client-helper";
import {BsCheckAll} from "react-icons/bs";
import RegisterStepButton from "./register-step-button";
import {Alert} from "@material-ui/lab";
import clsx from "clsx";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {
    GET_COURSE_DB_WITH_ROUNDS,
    GET_CURRENT_USER,
    GET_STUDENT_REGISTRATIONS,
    GET_STUDENT_REGISTRATIONS_OPTIMIZE_REGISTER
} from "../gql-query/select-query";
import {SAVE_REGISTER_DATA} from "../gql-query/save-query";
import {toast} from "react-toastify";
import moment from "moment";
import axios from "axios";
import {toJS} from "mobx";
import {getOrdinal} from "../helper";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        },
        '& .confirmBox': {
            border: '0px solid #4caf50',
            transition: 'border 300ms'
        },
        '& .success': {
            border: '10px solid #4caf50',
            transition: 'border 300ms'
        }
    }
}));

/**
 * @typedef {Object} RegistrationDetails
 * @property {number} registrationCount - The count of registrations.
 * @property {number} price - The price of the registration.
 */

const RegisterStep3 = ({courseDB, setCurrentStep}) => {
    const state = useMobxState();
    const classes = useStyles();
    const [confirm, setConfirm] = useState(false);
    const myRegistrations = useQuery(GET_STUDENT_REGISTRATIONS, {fetchPolicy: "network-only"});
    const scheduleFee = useRef({});
    const roundGroupBuffer = useRef([]);
    const [saveRegister] = useMutation(SAVE_REGISTER_DATA);
    const apolloClient = useApolloClient();
    const [ready,setReady] = useState(false);
    const [myRegisteredCount,setMyRegisteredCount] = useState(-1);
    const [totalFee,setTotalFee] = useState(0);
    const [disableBtn,setDisableBtn] = useState(false);
    const client = useApolloClient();

    useEffect(() => {
        state.registerStep3Data = [];
        state.reloadUserConfig(apolloClient).then(()=>setReady(true)).catch(e=>{});
        syncMyRegisteredCount();
    }, []);

    useEffect(() => {
        if (confirm) {
            let buffer = {
                rounds: state.registerSelectedRounds.map(v => ({id: v.id})),
                scheduleFee: Object.keys(scheduleFee.current).map(k => ({id: k, totalFee: scheduleFee.current[k]})),
            };
            state.registerStep3Data = buffer;
        }
    }, [confirm]);

    function registerRoundGroupBySchedule() {
        roundGroupBuffer.current = [...new Set(state.registerSelectedRounds.map(sel => {
            return sel.schedule.id;
        }))]
        roundGroupBuffer.current = roundGroupBuffer.current.map(schdID => state.registerSelectedRounds.find(v => v.schedule.id == schdID)?.schedule)
        return roundGroupBuffer.current;
    }

    function calculateWalletPay(fee){
        if(state.currentUser.wallet>=fee){
            return 0;
        }else{
            return `= ${fee-state.currentUser.wallet}`;
        }
    }

    function syncMyRegisteredCount(){
        let schedule = state.registerSelectedRounds?.[0]?.schedule;

        axios.post('/api/user-schedule-paid-detail',{
            scheduleID:schedule.id,
            scheduleRounds:state.registerSelectedRounds.map(v=>v.id)
        }).then(res=>{
            let regDetail = /** @type {RegistrationDetails} */ res.data;
            setMyRegisteredCount(regDetail.registrationCount);
            setTotalFee(regDetail.price);
        })

        // axios.get('/api/user-registered-count?scheduleID='+schedule.id).then(res=>{
        //     let {count} = res.data;
        //     setMyRegisteredCount(count);
        // })
    }

    const studentRegistrations = myRegistrations.data?.studentRegistrations;
    if (!studentRegistrations || !ready) return <div>Loading...</div>
    return <Box p={2} className={classes.root}>
        <Card className={clsx('confirmBox', {
            'success': confirm
        })}>
            <CardHeader title='Please confirm, your exam schedules'/>
            <CardContent>
                <Box p={2}>
                    {registerRoundGroupBySchedule().map((schd, i) => {
                        let fee = 0;
                        // let totalFee = 0;
                        let regTimes = myRegisteredCount;
                        // let regTimes = getRegistrationCountWithThis(
                        //     myRegistrations.data?.studentRegistrations,
                        //     schd.id
                        // );
                        // let existed = studentRegistrations.find(v => v.schedule.id == schd.id);
                        // if (existed) {
                        //     regTimes -= 1;
                        // }
                        let {courseDBbyID} = courseDB.data;


                        let dataBuffer=state?.registerSelectedRounds
                            .filter(v => v.schedule.id === schd.id)
                            .map(r => {
                                if (regTimes > courseDBbyID.freeTimes && courseDBbyID.isPaid) {
                                    fee = r.roundData.quizSet.price;
                                }
                                // totalFee += fee;
                                // scheduleFee.current = {
                                //     ...scheduleFee.current,
                                //     [schd.id]: totalFee,
                                // }
                                return {
                                    id:r.id,
                                    title:r.title,
                                    location:r.location,
                                    date:getThaiDateBetweenSingleDay(r.examStart,r.examEnd),
                                    fee:fee ? `${fee} THB` : 'Free',
                                }
                            })


                        return <Box key={schd.id} mt={2}>
                            {confirm &&
                            <Fade in={confirm} timeout={300}>
                                <Box p={2} align='center'><Typography variant='h4'
                                                                      style={{color: 'green'}}><BsCheckAll/>&nbsp;CONFIRMED</Typography></Box>
                            </Fade>
                            }
                            <Typography variant='h6'>{schd.title} / {getOrdinal(regTimes)}</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Exam type</TableCell>
                                            <TableCell>Location</TableCell>
                                            <TableCell>Date / Time</TableCell>
                                            <TableCell>Payment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataBuffer.map(r=>{
                                            return <TableRow key={r.id}>
                                                        <TableCell>{r.title}</TableCell>
                                                        <TableCell>{r.location}</TableCell>
                                                        <TableCell>{r.date}</TableCell>
                                                        <TableCell>Yes</TableCell>
                                                        {/*<TableCell>{r.fee}</TableCell>*/}
                                                    </TableRow>
                                            })
                                        }
                                        <TableRow>
                                            <TableCell colSpan='4' align='right'>
                                                {state.currentUser.wallet>0 && state.systemConfig.wallet_enabled ?
                                                    <>
                                                        {totalFee>0 &&
                                                            <>
                                                                <Typography variant='h6' style={{color:'#4caf50'}}>You have: {state.currentUser.wallet} Wallet</Typography>
                                                                <Typography variant='h6' style={{color:'#1e88e5'}}>Fee {totalFee} THB - {state.currentUser.wallet>totalFee?totalFee:state.currentUser.wallet} Wallet</Typography>
                                                            </>
                                                        }
                                                        <Typography
                                                            variant='h5'>{totalFee ? <>Total fee&nbsp;&nbsp;{calculateWalletPay(totalFee)} THB</> : 'FREE'}</Typography>
                                                    </>
                                                    :
                                                    <Typography
                                                        variant='h5'>{totalFee ? <>Total fee&nbsp;&nbsp;{totalFee} THB</> : 'FREE'}</Typography>
                                                }

                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    })}
                </Box>
                <Box display='flex' justifyContent='flex-end'>
                    <FormControlLabel
                        control={<Checkbox checked={confirm} name="confirm" onChange={e => setConfirm(!confirm)}/>}
                        label="Please confirm my schedule is valid."
                    />
                </Box>
                <RegisterStepButton condition={confirm}
                                    nextButtonText={disableBtn?'Processing...':'Confirm to register'}
                                    disabled={disableBtn}
                                    onBack={e => setCurrentStep(2)}
                                    onNext={e => {
                                        setDisableBtn(true);
                                        if(disableBtn)return;
                                        saveRegister({
                                            variables: {
                                                schedule:state.registerStep2Data?.scheduleID,
                                                rounds: state.registerStep3Data?.rounds
                                            }
                                        }).then(async res => {
                                            let {error} = res.data?.saveRegistration;
                                            if(error){
                                                toast.error(error);
                                                return;
                                            }
                                            try{
                                                await axios.get('/api/clear-cache');
                                                await client.resetStore();
                                            }catch (e){}
                                            toast.success('Register completed.');
                                            state.registerStepIsFinished=true;
                                            state.registerStep3Data={
                                                saveResult:res.data?.saveRegistration
                                            };
                                            try{
                                                let resUser = await apolloClient.query({
                                                    query: GET_CURRENT_USER,
                                                    fetchPolicy: 'network-only'
                                                })
                                                state.currentUser=resUser.data.currentUser;
                                            }catch(e){}

                                            setCurrentStep(4)
                                        }).catch(e => {
                                                toast.error(e.message);
                                        }).finally(()=>{
                                            setDisableBtn(false);
                                        })
                                    }}
                />
            </CardContent>
        </Card>
    </Box>
}
export default observer(RegisterStep3);
