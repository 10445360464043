// import * as ClassicEditor from "../ckeditor/ckeditor";
import {CKEditor} from "@ckeditor/ckeditor5-react";
// import {useEffect, useState} from "react";


const XCKEditor = ({onChange, onReady, value}) => {

    // const [editorLoaded, setEditorLoaded] = useState(false);

    // useEffect(() => {
    //     if (window.ClassicEditor) {
    //         setEditorLoaded(true);
    //         return;
    //     }
    //
    //     const script = document.createElement('script');
    //     script.src = '/v2/ckeditor.js';
    //     script.async = true;
    //
    //     script.onload = () => {
    //         setEditorLoaded(true);
    //         console.log('CKEditor loaded');
    //     };
    //
    //     document.body.appendChild(script);
    //
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    // if (!editorLoaded) {
    //     return <div>Loading editor...</div>;
    // }

    return <CKEditor
        editor={window.ClassicEditor}
        config={
            {
                allowedContent: true,
                htmlSupport: {
                    allow: [
                        {
                            name: 'iframe',
                            attributes: true
                        },
                        {
                            name: 'div',
                            attributes: true
                        }
                    ],
                    disallow: []
                },
                alignment: {
                    options: ['left', 'center', 'right']
                },
                toolbar: [
                    'heading', 'alignment','horizontalLine', '|',
                    'sourceEditing','uploadImage','mediaEmbed','htmlEmbed', 'table', 'insertTable', '|',
                    'bold', 'fontcolor', 'fontSize', 'fontbackgroundcolor', 'italic', 'link', 'undo', 'redo', 'bulletedList', 'numberedList', 'blockQuote','|',
                    'code', '|',
                ]
            }
        }
        data={value ? value : ''}
        onReady={editor => {
            if (typeof onReady == 'function') onReady(editor);
        }}
        onChange={(event, editor) => {
            const data = editor.getData();
            if (typeof onChange == 'function') onChange(data);
        }}
        onBlur={(event, editor) => {
            // console.log( 'Blur.', editor );
        }}
        onFocus={(event, editor) => {
            // console.log( 'Focus.', editor );
        }}
    />
}
export default XCKEditor;
